
import MoleculePinExpand from "~/components/molecules/project/MoleculePinExpand.vue";

export default {
  name: 'MoleculePin',
  components: {MoleculePinExpand},
  props: {
    pinData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    onExpand: {
      type: Function,
      required: true,
      default: () => {}
    },
    onClose: {
      type: Function,
      required: true,
      default: () => {}
    },
  },
  data() {
    return {
    }
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default();
    }
  },
  mounted() {
    // console.log(this.pinData);
  },
  methods: {
    openExpand() {
      if (!this.expanded && !this.pinData.no_content && !this.pinData.featured_link) {
        this.onExpand();
      }
      if (this.pinData.featured_link) {
        window.open(this.pinData.featured_link, '_blank');
      }
    },
    closeExpand() {
      this.onClose();
    },
  }
}
