
export default {
  name: 'MoleculeSidebarRoutes',
  props: {
    places: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    defaultMode: {
      required: false,
      type: String,
      default: 'walking'
    }
  },
  updated() {
    this.$el.querySelectorAll('.atom-location-type.active').forEach((item) => {
      const child = item.querySelector('.atom-route.atom-route-active')
      if (child) {
        child.classList.remove('atom-route-active')
      }
      item.classList.remove('active')
    })
  },
  methods: {
    handleCategoryToggle(target) {
      const atomRoute = document.querySelector('.atom-route.atom-route-active')
      if (atomRoute) {
        atomRoute.classList.remove('atom-route-active')
      }
      target.parentNode.parentNode.scrollTop = target.offsetTop
    }
  }
}
